#c4 {
  display: flex;
  justify-content: flex-start;
}

#image-container {
  width: 100px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#profilePic {
  width: 150px;
  margin-bottom: 20px;
}

#bio {
  margin-left: 20px;
}

#name {
  color: white;
  font-size: xx-large;
  text-shadow: 2px 2px 8px #4689a1;
  margin-left: 25px;
}

#resume {
  font-size: medium;
  text-decoration: none;
  text-shadow: none;
  color: #1556ce;
}

#forHire {
  text-align: left;
  font-size: small;
  margin-left: 25px;
}

@media screen and (max-width: 500px) {
  #name {
   font-size: x-large;
  }
}