body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 50px;
  margin-left: 350px;
  margin-right: 350px;
  background-color: #bfdfec;
  background-repeat: repeat-x;
  background-image: url("clouds.png");
  animation: movement 10s linear infinite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes movement {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 560px 0px;
  }
}

@media screen and (max-width: 1370px) {
  body {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 414px) {
  body {
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
  }
}
