iframe {
  border: 1px solid black;
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 375px) {
  iframe {
    width: auto;
    height: 350px;
  }
}
