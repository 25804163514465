#about {
  background-color: #d2eef8;
  list-style-type: none;
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px 18px #7b8a8f;
}

@media screen and (max-width: 500px) {
  #about {
   font-size: smaller;
  }
}